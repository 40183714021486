export enum ProductsIDEnum {
  LOADS = 1,
  ELECTRONIC_EQUIPMENT = 2,
  RC_CONTRACTORS = 3,
  PERSONAL_ACCIDENTS = 4,
  ORBE_360 = 5,
  ERN = 6
}

export enum ProductsEnum {
  LOADS = "loads",
  ELECTRONIC_EQUIPMENT = "ee",
  PERSONAL_ACCIDENTS = "ap",
  ERN = "ern"
}

export enum BusinessLinesEnum {
  ORBELINE = "orbeline",
  SOLAR_INSURANCE = "solar_insurance",
  ORBE_SEGUROS = "orbe_seguros"
}

export enum BusinessLinesIDEnum {
  ORBELINE = 1,
  SOLAR_INSURANCE = 2,
  ORBE_SEGUROS = 3
}
