// ** Reducers Imports
import navbar from "./navbar";
import layout from "./layout";
import { authReducer } from "./auth";
import ensuredGoods from "@src/views/Catalogs/EnsuredGoods/store";
import accountants from "@src/views/Users/Accountants/store";
import excludedGoods from "@src/views/Catalogs/ExcludedGoods/store";
import modesTransport from "@src/views/Catalogs/ModesTransport/store";
import taxData from "@src/views/Users/BusinessNames/store";
import providers from "@src/views/Catalogs/Clauses/store";
import validities from "@src/views/Catalogs/Validities/store";
import electronicEquipments from "@src/views/Catalogs/ElectronicEquipment/store";
import projectProfiles from "@src/views/Catalogs/ProjectProfile/store";
import providersCatalog from "@src/views/Catalogs/Providers/store";
import coverage from "@src/views/Catalogs/Coverage/store";
import sellers from "@src/views/Users/Sellers/store";
import clients from "@src/views/Users/Clients/store";
import admins from "@src/views/Users/Admins/store";
import concepts from "@src/views/Catalogs/Concepts/store";
import countries from "@src/views/Catalogs/Countries/store";
import wizard from "@src/views/Certs/store";
import maps from "./maps";
// import cert from "./certificate"
import response from "./response";
import filters from "./filters";
import modalTypeDelete from "./modalTypeDelete";
import modalTypeSuccess from "./modalTypeSuccess";
import modalTypeError from "./modalTypeError";
import modalTypeEdit from "./modalTypeEdit";
import modalTypeCustom from "./modalTypeCustom";
import modalTypeFullCustom from "./modalTypeFullCustom";
import modalTypeRenew from "./modalTypeRenew";
import modalEditCertUpload from "./modalEditCertUpload";
import address from "./address";
import destination from "./destination";
import fiscal from "./fiscal";
import certificate from "@src/views/Certs/store/certs";
import sellerList from "@src/views/Certs/store/sellers";
import clientList from "@src/views/Certs/store/clients";
import taxdataList from "@src/views/Certs/store/taxdata";
import invoices from "@src/views/Invoices/store/invoices";
import { paymentsReducer } from "./payments";
import commissions from "@src/views/Commissions/store";
import refunds from "@src/views/Commissions/store/refunds";
import history from "@src/views/Commissions/store/history";
// Dashboard
import dashboardFilters from "@src/views/Dashboards/store";
// Quotations
import quotations from "@src/views/Quotations/store";
import cancellations from "@src/views/Cancellations/store";
import modalSessionExpired from "./modalSessionExpired";
import modalClientActivation from "./modalClientActivation";
// Balances
import balances from "@src/views/Balances/store";
import balance_summary from "@src/views/Balances/store/balance_summary";
import products from "./products";
import plans from "@src/views/Catalogs/Plans/store";
import blocks from "./blocks";
// Notifications
import notifications from "@src/views/Configuration/Notifications/store/notifications";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { combineReducers } from "redux";
import { bankCardsReducer } from "./bank-cards";
import { modalConfirmReducer } from "./modals/modal-confirm";
import { paymentReferencesReducer } from "./payment-references";
import businessLines from "./businessLines";
import { sellerClientsReducer } from "./seller-clients";

const reducers = {
  accountants,
  address,
  admins,
  auth: authReducer,
  balance_summary,
  balances,
  bankCards: bankCardsReducer,
  cancellations,
  certificate,
  clientList,
  clients,
  commissions,
  concepts,
  countries,
  coverage,
  dashboardFilters,
  destination,
  electronicEquipments,
  ensuredGoods,
  excludedGoods,
  filters,
  fiscal,
  history,
  invoices,
  layout,
  maps,
  modalClientActivation,
  modalConfirm: modalConfirmReducer,
  modalEditCertUpload,
  modalSessionExpired,
  modalTypeCustom,
  modalTypeDelete,
  modalTypeEdit,
  modalTypeError,
  modalTypeFullCustom,
  modalTypeRenew,
  modalTypeSuccess,
  modesTransport,
  navbar,
  notifications,
  paymentReferences: paymentReferencesReducer,
  payments: paymentsReducer,
  plans,
  projectProfiles,
  providers,
  providersCatalog,
  quotations,
  refunds,
  response,
  sellerClients: sellerClientsReducer,
  sellerList,
  sellers,
  taxData,
  taxdataList,
  validities,
  wizard
};

const rootReducer = combineReducers({
  businessLines: persistReducer(
    {
      key: "businessLines",
      storage
    },
    businessLines
  ),
  products: persistReducer(
    {
      key: "products",
      storage
    },
    products
  ),
  blocks: persistReducer(
    {
      key: "blocks",
      storage
    },
    blocks
  ),
  ...reducers
});

export default rootReducer;
