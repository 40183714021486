import { createSlice } from "@reduxjs/toolkit";
import { ResponseStatusEnum, SellerClientsState } from "./clients.types";
import {
  createClient,
  getClientById,
  getProjectProfile,
  getSellerClients,
  updateClient,
  updateScheduledInvoiceByClient
} from "./async-thunks";

const initialState: SellerClientsState = {
  data: null,
  client: null,
  seller: null,
  taxData: null,
  managers: [],
  total: 0,
  defaultValues: null,
  saved: false,
  create: {
    status: ResponseStatusEnum.idle,
    message: null,
    data: null
  },
  profiles: {
    status: ResponseStatusEnum.idle,
    data: null
  },
  update: {
    status: ResponseStatusEnum.idle,
    message: null
  },
  sheduledInvoices: {
    status: ResponseStatusEnum.idle,
    data: null
  }
};

export const sellerClientsSlice = createSlice({
  name: "sellerClients",
  initialState,
  reducers: {
    resetClients: (state) => {
      state.client = null;
      state.seller = null;
      state.taxData = null;
      state.managers = [];
      state.total = 0;
      state.defaultValues = null;
      state.saved = false;
    },
    clearSaved: (state) => {
      state.saved = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getSellerClients.fulfilled, (state, action) => {
      const payload = action.payload.data;
      state.data = payload.data;
      state.total = payload.meta.total;
    });
    builder.addCase(getClientById.fulfilled, (state, action) => {
      const client = action.payload;
      state.client = client;
      state.seller = client.seller;
      state.taxData = client.taxData;
      state.managers = client.managers;
    });
    builder.addCase(createClient.pending, (state) => {
      state.create = {
        status: ResponseStatusEnum.loading,
        message: null,
        data: null
      };
    });
    builder.addCase(createClient.rejected, (state) => {
      state.create = {
        status: ResponseStatusEnum.error,
        message: "Error al obtener el cliente",
        data: null
      };
    });
    builder.addCase(createClient.fulfilled, (state, action) => {
      state.create = {
        status: ResponseStatusEnum.success,
        message: action.payload.message,
        data: action.payload.data
      };
      state.saved = true;
    });
    builder.addCase(getProjectProfile.pending, (state) => {
      state.profiles = {
        status: ResponseStatusEnum.loading,
        data: null
      };
    });
    builder.addCase(getProjectProfile.rejected, (state) => {
      state.profiles = {
        status: ResponseStatusEnum.error,
        data: null
      };
    });
    builder.addCase(getProjectProfile.fulfilled, (state, action) => {
      state.profiles = {
        status: action.payload.status,
        data: action.payload.data
      };
    });
    builder.addCase(updateClient.pending, (state) => {
      state.update = {
        status: ResponseStatusEnum.loading,
        message: null
      };
    });
    builder.addCase(updateClient.rejected, (state) => {
      state.update = {
        status: ResponseStatusEnum.error,
        message: "Error al actualizar el cliente"
      };
    });
    builder.addCase(updateClient.fulfilled, (state, action) => {
      state.update = {
        status: action.payload.status,
        message: action.payload.message
      };
    });
    builder.addCase(updateScheduledInvoiceByClient.pending, (state) => {
      state.sheduledInvoices = {
        status: ResponseStatusEnum.loading,
        data: null
      };
    });
    builder.addCase(updateScheduledInvoiceByClient.rejected, (state) => {
      state.sheduledInvoices = {
        status: ResponseStatusEnum.error,
        data: null
      };
    });
    builder.addCase(
      updateScheduledInvoiceByClient.fulfilled,
      (state, action) => {
        state.sheduledInvoices = {
          status: action.payload.status,
          data: action.payload.data
        };
      }
    );
  }
});

export const { clearSaved, resetClients } = sellerClientsSlice.actions;

export const sellerClientsReducer = sellerClientsSlice.reducer;
